<template>
  <b-modal
    v-if="modalData"
    :id="'reply-modal-' + modalData.id"
    :title="`From - ${modalData.employee ? modalData.employee.name : 'Anonymous'}`"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @hidden="clearForm"
    @shown="setData"
  >
    <b-form class="reply-modal" @submit="handleSubmit">
      <label>Feedback</label>
      <div class="limit" v-html="modalData.content"></div>
      <b-form-group
        v-if="modalData.next_status === 'all'"
        label="Status"
        label-for="status"
        class="required"
      >
        <b-form-select
          value-field="id"
          text-field="title"
          v-model="form.status"
          :options="statuses"
          required
        />
      </b-form-group>
      <b-form-group
        label="Reply"
        label-for="reply"
        class="required"
      >
        <b-form-textarea
          id="reply"
          v-model="form.note"
          required
        />
      </b-form-group>
      <div class="text-right">
        <button :class="`btn btn-${statusData.class} text-right`">{{ statusData.btnText }}</button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { getFinalMark } from '@/utils/marks';

export default {
  name: 'StaffRatingForm',
  props: [
    'modalData',
    'initialData'
  ],
  data() {
    return {
      getFinalMark,
      form: {
        note: '',
        next_status: null,
        status: null
      },
      canParseData: true,
      statuses: [
        { id: 'sent', title: 'Sent', class: 'secondary', btnText: 'Save' },
        { id: 'active', title: 'Active', class: 'primary', btnText: 'Accept' },
        { id: 'declined', title: 'Declined', class: 'danger', btnText: 'Decline' },
        { id: 'done', title: 'Done', class: 'success', btnText: 'Save' },
      ],
    }
  },
  computed: {
    statusData () {
      return this.statuses.find(e => e.id === this.form.status) || {id: 'send', title: 'Send', class: 'secondary', btnText: 'Save'}
    }
  },
  methods: {
    clearForm() {
      this.form.note = '';
      this.form.next_status = null;
      this.form.status = null;
    },
    handleSubmit(event) {
      event.preventDefault();
      this.form.next_status = this.form.status
      this.$emit('onSubmit', this.form);
      this.clearForm()
    },
    setData() {
      this.form.status = this.modalData.status;
      this.form.note = this.modalData.reply;
      this.form.next_status = this.modalData.next_status !== 'all' ? this.modalData.next_status : null
    }
  },
}
</script>

<style>
.reply-modal img, .reply-modal figure {
  max-width: 200px;
}
</style>
