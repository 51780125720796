<template>
  <Layout v-load="loading">
    <div class="d-flex justify-content-between mb-3 mt-3">
      <h3>Feedbacks</h3>
      <div class="row col-9">
        <div class="col-md-6">
          <multiselect track-by="id" :multiple="true" selectLabel="" deselectLabel="" v-model="selectedStatus" :options="statuses" @input="changeStatusHandler" label="title" placeholder="Select status"></multiselect>
        </div>
        <div class="col-md-3">
          <date-picker value-type="YYYY-MM-DD" format="DD.MM.YYYY" id="dateRange" v-model="dateRangeSurveyEvent" type="date" range append-to-body lang="en" confirm placeholder="filter by period"></date-picker>
        </div>
        <div class="col-md-3 search pr-0">
          <Search
              @onSubmit="handleSearch"
          />
        </div>
      </div>
    </div>
    <div class="card">
      <div
          v-if="sortedList.length > 0"
          class="table-responsive feedbacks"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
          <tr>
            <th class="id-column"><div>#</div></th>
            <th>From</th>
            <th>Feedback</th>
            <th>Reply</th>
            <th width="150">Date</th>
            <th width="150">Status</th>
            <th class="text-right">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in sortedList"
              :key="item.id"
              class="feedbacks__item"
          >
            <td @click="showFeedback(item)" class="id-column"><div>{{ index + 1 }}</div></td>
            <td @click="showFeedback(item)">{{ item.employee ? item.employee.name : 'Anonymous' }}</td>
            <td @click="showFeedback(item)"><div class="limit" v-html="item.content"></div></td>
            <td>{{ item.reply }}</td>
            <td width="150">{{ convertDate(item.created_at) }}</td>
            <td class="">
              <b-badge :variant="item.status === 'sent' ? 'secondary' : item.status === 'done' ? 'success' : item.status === 'declined' ? 'danger' : 'primary'">{{ item.status }}</b-badge>
            </td>
            <td class="d-flex justify-content-end">
              <i
                v-for="(status, index) in nextStatuses(item.status)"
                :key="index"
                v-b-tooltip.hover
                :title="status.text"
                @click="showReplyForm(item, status.id)"
                :class="status.class"
                class="bx font-size-20 mr-3"
              ></i>
<!--              <button class="btn btn-sm btn-secondary" @click="showReplyForm(item, 'all')">-->
<!--                Edit-->
<!--              </button>-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center p-3 font-size-16 text-muted" v-else><em>Feedbacks not found</em></div>
    </div>
    <Reply :modalData="modalData" @onSubmit="handleSubmit" />
    <Info :modalData="modalData" @onClose="handleCloseInfo" />
  </Layout>
</template>
<script>
import Layout from '@/router/layouts/main.vue';
import moment from "moment";
import Multiselect from 'vue-multiselect'
import DatePicker from "vue2-datepicker";
import Search from '@/components/search.vue';
import Reply from './reply.vue';
import Info from './info.vue';

export default {
  name: 'FeedbacksPage',
  components: {
    Layout,
    DatePicker,
    Search,
    Multiselect,
    Reply,
    Info
  },
  data() {
    return {
      currentRow: null,
      dateRangeSurveyEvent: null,
      filter: {
        startDate: null,
        endDate: null,
        search: null,
        page: 1,
      },
      selectedStatus: [],
      statuses: [
        { id: 'sent', title: 'Sent' },
        { id: 'active', title: 'Active' },
        { id: 'declined', title: 'Declined' },
        { id: 'done', title: 'Done' },
      ],
      modalData: null
    }
  },
  mounted() {
    this.$store.dispatch('feedback/getFeedback', {params: this.filter});
    this.selectedStatus = this.filterStatus.status || []
  },
  computed: {
    list() {
      return this.$store.state.feedback.list;
    },
    filterStatus() {
      return this.$store.state.feedback.filterStatus;
    },
    loading() {
      return this.$store.state.feedback.loading;
    },
    sortedList() {
      if (!this.selectedStatus.length) {
        return this.list
      }
      return this.list.filter(item => this.selectedStatus.some(e => e.id === item.status));
    },
  },
  methods: {
    convertDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    showFeedback(data) {
      this.modalData = Object.assign({}, data);
      setTimeout(() => {
        this.$bvModal.show('info-modal-' + data.id);
      }, 10)
      // Swal.fire({
      //   width: 1000,
      //   html: row.content,
      //   showCloseButton: false,
      // });
    },
    handleSearch(search) {
      this.filter.search = search;
      this.$store.dispatch('feedback/getFeedback', {params: this.filter});
    },
    showReplyForm(data, nextStatus) {
      data.next_status = nextStatus;
      this.modalData = Object.assign({}, data);
      if (nextStatus !== 'all') {
        this.modalData.status = nextStatus
      }
      setTimeout(() => {
        this.$bvModal.show('reply-modal-' + data.id);
      }, 10)
    },
    async handleSubmit(data) {
      const fData = new FormData();
      fData.append('status', data.next_status);
      fData.append('reply', data.note);
      fData.append('_method', 'PUT');
      await this.$store.dispatch('feedback/replyFeedback', {
        id: this.modalData.id,
        data: fData
      });
      this.$store.dispatch('feedback/getFeedback', {params: this.filter});
      this.$bvModal.hide('reply-modal-' + this.modalData.id);
      this.modalData = null
    },
    handleCloseInfo() {
      this.$bvModal.hide('info-modal-' + this.modalData.id);
      this.modalData = null
    },
    nextStatuses(status) {
      if (status === 'sent') {
        return [
          {
            id: 'active',
            text: 'Accept',
            class: 'bx-check text-primary',
          },
          {
            id: 'all',
            text: 'Edit',
            class: 'bx-pencil text-warning',
          },
          {
            id: 'declined',
            text: 'Decline',
            class: 'bx-x text-danger',
          }
        ]
      } else if (status === 'active') {
        return [
          {
            id: 'done',
            text: 'Complete',
            class: 'bx-check text-success',
          },
          {
            id: 'all',
            text: 'Edit',
            class: 'bx-pencil text-warning',
          },
          {
            id: 'declined',
            text: 'Decline',
            class: 'bx-x text-danger',
          }
        ]
      } else if (status === 'done') {
        return [
          {
            id: 'all',
            text: 'Edit',
            class: 'bx-pencil text-warning',
          },
        ]
      } else if (status === 'declined') {
        return [
          {
            id: 'all',
            text: 'Edit',
            class: 'bx-pencil text-warning',
          },
        ]
      }
    },
    changeStatusHandler() {
      this.$store.commit('feedback/SET_FILTER_STATUS', {
        key: 'status',
        value: this.selectedStatus
      })
    }
  },
  watch: {
    dateRangeSurveyEvent(val) {
      this.filter.startDate = val[0] ? moment(val[0]).format('YYYY-MM-DD') : null;
      this.filter.endDate = val[1] ? moment(val[1]).format('YYYY-MM-DD') : null;
      this.$store.dispatch('feedback/getFeedback', {params: this.filter});
    }
  }
}
</script>
<style lang="scss" scoped>
.limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.feedbacks .table tbody tr {
  cursor: pointer;
}

.search {
  width: 300px;
}
</style>
<style>
.multiselect__select:before {
  top: 35% !important;
}
</style>
<style>
.feedbacks__item img, .feedbacks__item figure {
  max-width: 200px;
}
.swal2-popup img {
  max-width: 200px;
}
</style>
