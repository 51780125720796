export const getFinalMark = (marks) => {
  const { max, fact } = marks.reduce((acc, item) => {
    const { question, mark } = item;
    acc.max += question.coefficient * (question.coefficient > 0 ? 10 : 0);
    acc.fact += question.coefficient * mark;

    return acc;
  }, { max: 0, fact: 0 });

  return (100.0 * fact / max).toFixed(2);
};

export const getMediumValue = (records) => {
  if (records.length) {
    const { max, fact } = records.reduce((acc, item) => {
      const { coefficient, assessment_record: { marks } } = item;
      acc.max += 100 * (coefficient ? coefficient : 1.0);
      acc.fact += getFinalMark(marks) * (coefficient ? coefficient : 1.0);
      return acc;
    }, { max: 0, fact: 0 });

    return parseInt(100 * 100 * fact / max) / 100.0;
  }

  return null;
};