<template>
  <b-modal
    v-if="modalData"
    :id="'info-modal-' + modalData.id"
    :title="`From - ${modalData.employee ? modalData.employee.name : 'Anonymous'}`"
    centered
    scrollable
    title-class="font-22"
    ok-only
    @hidden="clearForm"
    @shown="setData"
    hide-footer
  >
    <div class="info-modal">
      <label>Feedback</label>
      <div class="limit" v-html="modalData.content"></div>
      <label v-if="modalData.reply">Reply</label>
      <p>{{ modalData.reply }}</p>
      <div class="text-right">
        <button @click="handleClose" class="btn btn-primary text-right">Close</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getFinalMark } from '@/utils/marks';

export default {
  name: 'StaffRatingForm',
  props: [
    'modalData',
    'initialData'
  ],
  data() {
    return {
      getFinalMark,
    }
  },
  methods: {
    clearForm() {
    },
    setData() {
    },
    handleClose(event) {
      event.preventDefault();
      this.$emit('onClose');
      this.clearForm()
    },
  },
}
</script>

<style>
.info-modal img, .info-modal figure {
  max-width: 100% !important;
}
</style>
